// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/MonetaryValue.ts"
);
import.meta.hot.lastModified = "1738830140000";
}
// REMIX HMR END

export const convertToMonetaryValue = (
  rawValue: number | string,
  invalidString = '0.00'
) => {
  if (!rawValue) return invalidString ?? '';
  switch (typeof rawValue) {
    case 'number': {
      return rawValue
        ?.toFixed(2)
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    case 'string': {
      const numValue = parseFloat(rawValue);
      return isNaN(numValue)
        ? rawValue
        : numValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    default: {
      return rawValue;
    }
  }
};
